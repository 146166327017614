var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0 pt-1"},[_c('v-card',{staticClass:"data-table",class:_vm.canBeSelected ? 'data-table--selectable' : '',attrs:{"elevation":"4"}},[_c('v-data-table',{staticClass:"elevation-0 custom-data-table",attrs:{"calculate-widths":"","headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"sort-by":_vm.sortByComputed.value,"sort-desc":_vm.sortDesc,"hide-default-footer":"","hide-default-header":"","loading":_vm.itemsLoading},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:page":function($event){_vm.page=$event},"update:sortBy":function($event){return _vm.$set(_vm.sortByComputed, "value", $event)},"update:sort-by":function($event){return _vm.$set(_vm.sortByComputed, "value", $event)},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var isMobile = ref.isMobile;
var props = ref.props;
var on = ref.on;
return [_c('table-custom-sort',_vm._g({attrs:{"isMobile":isMobile,"props":props,"v-on":on,"canBeSelected":_vm.canBeSelected,"allSelected":_vm.allSelected},on:{"selectAll":_vm.handleSelectAll}},_vm.$listeners))]}},{key:"item.select",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"select-all"},[(_vm.canBeChecked)?_c('checkbox',{attrs:{"checked":_vm.checkedItems.includes(item.id)},on:{"change":function($event){return _vm.handleSelectItem(item)}}}):_vm._e()],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex name-item item-bordered",class:[item.featured ? 'item-bordered--yellow' : '']},[_c('v-avatar',{staticClass:"mr-6",attrs:{"size":64,"elevation":"0","color":"white"}},[_c('v-img',{attrs:{"src":item.screenshot_url}})],1),_c('div',{staticClass:"d-flex flex-column"},[_c('div',[_c('span',{staticClass:"bold p-3"},[_vm._v(_vm._s(item.name))]),(item.featured)?_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99","offset-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 primary-on-hover",attrs:{"color":"gray lighten-1","size":"16"}},'v-icon',attrs,false),on),[_vm._v(" $alertcircle ")])]}}],null,true)},[_c('span',{staticClass:"base-text"},[_vm._v(_vm._s(_vm.$t('tooltip.instance.theme.add.special')))])]):_vm._e(),(item.featured)?_c('div',{staticClass:"featured-chip ml-2"},[_vm._v(_vm._s(_vm.$t('general.featured')))]):_vm._e()],1),_c('span',{staticClass:"p-4 item-desc mt-1"},[_c('span',{staticClass:"base--text",domProps:{"innerHTML":_vm._s(_vm.getDescription(item.description))}}),(item.description.length > 100)?_c('a',{staticClass:"info--text font-weight-bold ml-0",on:{"click":function($event){return _vm.$emit('action-button-read-more', item)}}},[_c('span',{staticClass:"base--text font-weight-regular"},[_vm._v("... ")]),_vm._v(" "+_vm._s(_vm.$t('button.readMore'))+" ")]):_vm._e()])])],1)]}},{key:"item.version",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t('table.field.version', {version: item.version })))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex actions-row"},[(item.preview_url)?_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"min-width":"38px","max-width":"38px"},attrs:{"outlined":"","small":"","color":"gray","elevation":"0","target":"_blank","href":item.type ? item.preview_url : ("https://wp-themes.com/" + (item.slug))}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-0",attrs:{"size":"18","color":"gray darken-2"}},[_vm._v(" $eye ")])],1)]}}],null,true)},[_c('span',{staticClass:"base--text"},[_vm._v(_vm._s(_vm.$t('tooltip.instance.theme.preview')))])]):_vm._e(),_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",staticStyle:{"min-width":"38px","max-width":"38px"},attrs:{"color":"primary","small":"","elevation":"0"},on:{"click":function($event){return _vm.$emit('action-button-install', item)}}},'v-btn',attrs,false),on),[(
                    !_vm.loading &&
                    !item.hasInstallInProgress() &&
                    !item.hasUpdateInProgress()
                  )?_c('v-icon',{staticClass:"mr-0",attrs:{"size":"18","color":"white"}},[_vm._v(" $installIcon ")]):_c('loader',{staticClass:"mr-0",attrs:{"color":"white","size":"24","noMargins":true}})],1)]}}],null,true)},[_c('span',{staticClass:"base--text"},[_vm._v(_vm._s(_vm.$t('tooltip.instance.theme.install')))])])],1)]}},{key:"item.rating",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"20","percent":item.rating / 100}},[_vm._v("$starIcon")]),_c('b',{staticClass:"p-4"},[_vm._v(_vm._s(Math.ceil((item.rating / 10) * 5) / 10))])],1)]}},{key:"no-data",fn:function(){return [(!_vm.searchTerm)?_c('span',[_vm._v(_vm._s(_vm.$t('heading.instance.advanced.theme.add.search')))]):_c('data-iterator-no-results-container',{attrs:{"searchTerm":_vm.searchTerm},on:{"clearAllFilters":function($event){return _vm.$emit('clearAllFilters')}}})]},proxy:true},{key:"footer",fn:function(){return [_c('div',[_c('data-iterator-footer',_vm._g({attrs:{"page":_vm.page,"numberOfPages":_vm.numberOfPages,"possibleItemsPerPage":_vm.possibleItemsPerPage,"listStyle":_vm.listStyle,"itemsPerPage":_vm.itemsPerPage,"itemsTotal":_vm.itemsTotal,"perPageLabel":_vm.$t('table.footer.theme')}},_vm.$listeners))],1)]},proxy:true},(_vm.itemsLoading)?{key:"body",fn:function(){return [_c('NewThemeTableItemSkeleton',{attrs:{"rowsNumber":_vm.itemsPerPage}})]},proxy:true}:null],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }