var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item data-iterator-card",class:[
    _vm.checked ? 'data-iterator-card--selected' : '',
    _vm.item.status === 'active' ? 'data-iterator-card--green' : '' ]},[_c('v-card',{attrs:{"elevation":"4"}},[_c('div',{staticClass:"data-iterator-card__checkbox-container"},[_c('checkbox',{attrs:{"checked":_vm.checkedComputed},on:{"change":_vm.handleCheckbox}})],1),(_vm.item.img)?_c('v-img',{staticClass:"data-iterator-card__image",attrs:{"position":"top center","src":_vm.item.img,"width":"100%","height":"200px","max-height":"200px"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('image-loader')]},proxy:true}],null,false,771853520)}):_vm._e(),_c('div',{staticClass:"instance-actions px-12"},[_c('div',{staticClass:"instance-actions__content text-center"},[(_vm.item.status !== 'active')?_c('v-btn',{staticClass:"mb-4",attrs:{"color":"primary","elevation":"0","small":"","max-width":"184px","min-width":"184px"},nativeOn:{"click":function($event){return _vm.$emit('action-button-activate', _vm.item)}}},[_c('span',{staticClass:"font-weight-bold white--text"},[_vm._v(_vm._s(_vm.$t('button.theme.use')))])]):_vm._e(),_c('v-btn',{attrs:{"color":"white","elevation":"0","small":"","max-width":"184px","min-width":"184px","target":"_blank","href":("https://wp-themes.com/" + (_vm.item.name))}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('button.preview')))])])],1)]),_c('v-divider'),_c('v-card-title',{staticClass:"d-flex justify-space-between align-center"},[_c('h5',{domProps:{"innerHTML":_vm._s(_vm.item.title)}}),_c('div',{staticClass:"d-flex align-center"},[(_vm.item.update !== 'available')?_c('v-tooltip',{attrs:{"open-delay":"150","bottom":"","transition":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.item.status === 'active' ? 'primary' : 'gray darken-1',"size":"16"}},'v-icon',attrs,false),on),[_vm._v(" $alertok ")])]}}],null,false,2740350383)},[_vm._v(" "+_vm._s(_vm.$t('tooltip.instance.theme.update.current'))+" ")]):_c('v-tooltip',{attrs:{"open-delay":"150","bottom":"","transition":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error","size":"16"}},'v-icon',attrs,false),on),[_vm._v("$alertwarning")])]}}])},[_vm._v(" "+_vm._s(_vm.$t('tooltip.instance.theme.update.available'))+" ")])],1)]),_c('v-card-text',{staticClass:"p-3"},[_c('span',{staticClass:"base--text",domProps:{"innerHTML":_vm._s(_vm.getDescription(_vm.item.description))}}),(_vm.hasReadMoreButton)?_c('a',{staticClass:"truncate-text",on:{"click":function($event){return _vm.$emit('action-button-read-more')}}},[_c('span',{staticClass:"base--text font-weight-regular"},[_vm._v(" ... ")]),_vm._v(" "+_vm._s(_vm.$t('button.readMore'))+" ")]):_vm._e()]),_c('v-card-actions',{staticClass:"pt-2 px-4"},[(_vm.item.hasInstallInProgress())?_c('div',{staticClass:"d-flex w-100 justify-space-between"},[_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('base-icon-button',_vm._g(_vm._b({attrs:{"icon":"$update","text":_vm.$t('button.installing'),"loading":""}},'base-icon-button',attrs,false),on))],1)]}}],null,false,2420992239)},[_c('span',{staticClass:"base--text"},[_vm._v(_vm._s(_vm.$t('message.status.install.inProgress')))])])],1):(_vm.item.hasUpdateInProgress())?_c('div',{staticClass:"d-flex w-100 justify-space-between"},[_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('base-icon-button',_vm._g(_vm._b({attrs:{"icon":"$update","text":_vm.$t('button.updating'),"loading":""}},'base-icon-button',attrs,false),on))],1)]}}])},[_c('span',{staticClass:"base--text"},[_vm._v(_vm._s(_vm.$t('message.tatus.update.inProgress'))+" ")])])],1):_c('div',{staticClass:"w-100 d-flex justify-space-between"},[_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('base-icon-button',_vm._g(_vm._b({attrs:{"disabled":_vm.item.status === 'active',"icon":_vm.item.status === 'active' ? '$xcircle' : '$checkcircle',"text":_vm.item.status === 'active' ? _vm.$t('message.status.activated') : _vm.$t('message.status.active')},nativeOn:{"click":function($event){return _vm.$emit('action-button-activate', _vm.item)}}},'base-icon-button',attrs,false),on))],1)]}}])},[_c('span',{staticClass:"base--text"},[(_vm.item.status !== 'active')?_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.instance.theme.activated.notActive')))]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('tooltip.instance.theme.activated.active')))])])]),_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('base-icon-button',_vm._g(_vm._b({attrs:{"icon":"$eye","text":_vm.$t('button.preview'),"target":"_blank","href":("https://wp-themes.com/" + (_vm.item.name))}},'base-icon-button',attrs,false),on))],1)]}}])},[_c('span',{staticClass:"base--text"},[_vm._v(_vm._s(_vm.$t('tooltip.instance.theme.preview')))])]),(_vm.item.update === 'available')?_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('base-icon-button',_vm._g(_vm._b({attrs:{"color":"error","icon":"$update","text":_vm.$t('button.update')},nativeOn:{"click":function($event){return _vm.$emit('action-button-update', _vm.item)}}},'base-icon-button',attrs,false),on))],1)]}}],null,false,2347384271)},[_c('span',{staticClass:"base--text"},[_vm._v(_vm._s(_vm.$t('tooltip.instance.theme.update')))])]):_vm._e()],1),_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('base-icon-button',{attrs:{"icon":"$thrash","text":_vm.$t('button.remove')},nativeOn:{"click":function($event){return _vm.$emit('action-button-delete', _vm.item)}}})],1)]}}])},[_c('span',{staticClass:"base--text"},[_vm._v(_vm._s(_vm.$t('tooltip.instance.theme.delete')))])])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }